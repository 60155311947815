import $ from 'jquery'

export default {
  data: function () {
    return {
      sort: {
        key: '',
        direction: ''
      }
    };
  },
  methods: {
    // label:valueのmetadata配列から指定したkeyの値を取り出す
    findMetadata: function(list, key) {
      return (list ? list.find(item => item.label == key).value : '')
    },
    // 図像・事物一覧の表示用に配列を8アイテムずつに分ける
    list4table: function(list) {
      const split = (array, n) => array.reduce((a, c, i) => i % n == 0 ? [...a, [c]] : [...a.slice(0, -1), [...a[a.length - 1], c]], [])
      return split(list, 8)
    },
    // 一覧のヘッダーにある並び替えの△を表示
    thClass (key) {
      if (this.sort.key === key) {
        if (this.sort.direction === 'asc') {
          return 'sort-1'
        }
        if (this.sort.direction === 'desc') {
          return 'sort-2'
        }
      }
      return 'sort-3'
    },
    // 一覧の並び替えの状態を切り替え
    sortBy (key) {
      this.sort.key = key
      if (this.sort.direction === 'asc') {
        this.sort.direction = 'desc'
      } else {
        this.sort.direction = 'asc'
      }
    }
  },
  created() {
    // 元からあったjQuery
    $.fn.adjustPageHeight = function()
    {
      var bg = $('#BG');
      var content = $('#CONTENT');
      var isIE6 = bg.data('isIE6');
      var ih = $('html').innerHeight() - (isIE6?1:0);
      var prop = isIE6 ? 'height' : 'min-height';
      if(content.outerHeight() < ih) bg.css(prop, ih);
      else bg.css(prop, 'auto');
      return this;
    };

    $.fn.adjustBoxHeight = function()
    {
      var prop = $('#BG').data('isIE6') ? 'height' : 'min-height';
      var max = 0;
      this.each(function()
      {
        var h = $(this).height();
        if(max < h) max = h;
      });
      return this.each(function()
      {
        $(this).css(prop, max);
      });
    };

    $.fn.buildPaginate = function()
    {
      return this.each(function()
      {
      //$(this).children('li:has(a)').addClass('page-link').end().children(':first').wrap($('<li class="prev"></li>')).end().children(':last').wrap($('<li class="next"></li>')).parent().prev(':not(li.prev)').addClass('last');
      $(this).children('li:has(a)').addClass('page-link').end()
      });
    };

    $.fn.pretendSelect = function($baseClassName)
    {
      var jq = this;
      return this.each(function()
      {
      var ul = $(this);
      var tag = this.tagName.toUpperCase();
      
      if(tag == 'SELECT')
      {
        var select = ul;
        var html = '<ul>';
        select.children('option').each(function()
        {
        html += '<li ' + (this.selected ? 'class="selected"' : '') + '>';
        html += '<a href="' + this.value + '">';
        html += this.text + '</a></li>';
        });
        html += '</ul>';
        ul = select.hide().after(html).next().data('isSelectTag', true).data('substance', select);
      }
      
      var tags = '<span class="lt"></span><span class="rt"></span><span class="lb"></span><span class="rb"></span>';
      var collapsed = $('<div class="'+$baseClassName+'-collapsed">'+tags+'<p></p></div>');
      var selected = ul.children('li.selected:first');
      var label = selected.length ? selected.text() : ul.children('li:first').addClass('selected').text();
      collapsed.children('p:first').text(label).end().data('expanded', ul);
      ul.prepend('<li class="first"><a href="">'+label+'</a></li>').before(collapsed).appendTo('body:first').wrap('<div class="'+$baseClassName+'-expanded"></div>').before(tags).data('collapsed', collapsed).data('jQuery', jq);
      
      ul.find('a').click(function()
      {
        var self = $(this);
        var ul = self.parent().parent();
        ul.parent().hide();
        $(document).unbind('click');
        
        var li = self.parent();
        if(li.hasClass('selected') || li.hasClass('first')) return false;
        
        var text = self.text();
        ul.children('li.selected:first').removeClass('selected');
        li.addClass('selected');
        ul.find('li.first a').text(text);
        ul.data('collapsed').find('p:first').text(text);
        
        if(ul.data('isSelectTag'))
        {
        ul.data('substance').val(text);
        $('#SEARCH-ASSOCIATION').submit();
        return false;
        }
      }).dblclick(function(){return false});
      
      collapsed.click(function()
      {
        var self = $(this);
        var o = self.offset();
        var expanded = self.data('expanded').parent().show().css({'left':o.left, 'top':o.top});
        $(document).click(function()
        {
        expanded.hide();
        $(this).unbind('click');
        });
        return false;
      });
      
      if(tag == 'SELECT')
      {
        var copy = collapsed.clone(true).data('expanded', ul);
        select.data('instance', copy);
      }
      });
    };

    $.fn.getInstance = function()
    {
      return this.eq(0).data('instance');
    };

    $.fn.ebiki = function($x, $y, $w, $h)
    {
      return this.each(function()
      {
        var self = $(this);
        var img = self.children('img:first');
        var mask = self.children('div.mask:first').stop();
        if(!mask.length)
        {
          mask = $('<div class="mask"></div>').width(img.width()).height(img.height()).fadeTo(0, 0, function()
          {
            img.after($(this));
          });
        }
        var p = self.children('p:first');
        if(!p.length) p = $('<p></p>').css('position', 'absolute').appendTo(self);
        p.width($w).height($h).css({'left':$x, 'top':$y, 'background-size':'300px', 'background-image':'url('+img.attr('src')+')', 'background-position':'-'+$x+'px -'+$y+'px'});
        mask.fadeTo('fast', 0.5);
      });
    };

    $.fn.cancelEbiki = function()
    {
      return this.each(function()
      {
        $(this).children('div.mask:first').stop().fadeTo('fast', 0, function()
        {
          $(this).parent().children('p:first').width(0);
        });
      });
    };
  },
  mounted() {
    // 昔からあったjQuery
    $(function(){
      var jq = $.support;
      $('#BG').data('isIE6', (jq.msie && jq.version=='6.0')?true:false);
      
      $.fn.adjustPageHeight();
      $(window).resize($.fn.adjustPageHeight);
      
      var selector = $.support.safari ? 'body' : 'html';
      $('p.pagetop a').click(function()
      {
        $(selector).animate({scrollTop:0}, 'fast');
        return false;
      });
      
      $('#CONTENT-BODY div.h2-binder:has(p.date)').each(function()
      {
        var self = $(this);
        var p = self.children('p.date:first');
        p.css('top', Math.round(self.innerHeight()/2 - p.height()/2));
        var pw = (p.width() > 250 ? p.width() : 250);
        self.children('h2').css('margin-right', pw+20);
        p.width(pw+10);
      });
      
      $('#CONTENT-BODY div.paginate').buildPaginate();
      $('#CONTENT-BODY div.controller ul.limit').pretendSelect('select');

      $('.h1-binder #SEARCH-FORM input.keyword[value=""]')  
        .val("絵引で探す")
        .css({"color":"gray"});
      $(".h1-binder #SEARCH-FORM input.keyword")  
          .focus(function() {  
              if ($(this).val()=="絵引で探す") { $(this).val(""); $(this).css({"color":"black"}); }  
          })  
          .blur(function() {  
              if ($(this).val()=="") { $(this).val("絵引で探す"); $(this).css({"color":"gray"}); }  
          });  

      if(window.ActiveXObject && $('#BG').data('isIE6') == false) $('#CONTENT-BODY div.box-binder:first').css('margin-bottom', 25);
      $('#CONTENT-BODY div.base-skin div.inner:gt(1)').adjustBoxHeight();
      $('#CONTENT-BODY dl.annotation dt').append('：');
    });
  }
};